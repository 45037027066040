<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title id="title">
                  {{ $t("ticket.Tickets") }}
                </v-toolbar-title>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_tickets"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                :btn_loading="btn_loading"
                @event_btn="event_btn"
                @change_pre_page="ChangePrePage"
                @get_data_paginate="handlePageChange"
                @action="action"
                :loading="loading"
                ref="ticketTable"
                :btns="btns"
                :headers="header"
                :items="tickets"
                :paginate="paginate"
                :btn_table="btn_table"
                @set_option="set_option"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import FormFilter from "../Components/Filter.vue";
import Notify from "../Components/Old/Notify.vue";
import TicketService from "../../services/ticket.service";
export default {
  name: "Ticket-Page",
  components: {
    Table,
    Notify,
    FormFilter,
  },
  data() {
    return {
      options: {},
      btn_table: {
        excel: true,
        print: true,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      loading: true,
      filters: [],
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "details",
          color: "bg-gradient-info",
          icon: "mdi-eye",
          permission: "ticket-details",
        },
        // {type:'icon',text : "forword_ticket",color:'bg-gradient-success',icon:'mdi-arrow-right-top'},
        // {type:'icon',text : "destroy",color:'bg-gradient-danger',icon:'mdi-delete'},
      ],

      header: [
        {
          text: this.$i18n.t("order.customer"),
          value: "applicant_ticket",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.content"),
          value: "content",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.importance_level"),
          value: "chips_level",
          align: "center",
        },
        {
          text: this.$i18n.t("order.status"),
          value: "status_text",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.subject"),
          value: "ticket_subjects.subject",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      tickets: [],
      href: "",
      download: "",
      paginate: {
        page: 1,
        itemsPerPage: 0,
        search: "",
        total: 0,
      },
    };
  },

  methods: {
    set_option(val) {
      this.options = val;
    },
    get_tickets(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        // console.log(this.filters);
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      return TicketService.get_ticket_admin(this.paginate.page, formData).then(
        (response) => {
          // console.log(response);
          this.tickets = response.data.tickets.data;
          this.paginate.total = response.data.tickets.total;
          this.paginate.itemsPerPage = parseInt(response.data.tickets.per_page);
          this.loading = false;
          const { sortBy, sortDesc } = this.options;
          let items = this.tickets;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }
          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.$refs.ticketTable.resetOption();
          }
        },
        (error) => {
          // console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    action(id, name) {
      if (name == "details") {
        this.details(id);
      }
    },
    details(id) {
      this.$router.push({ path: `/ticket/details/${id.toString()}` });
    },
    handlePageChange(page) {
      // console.log(page);
      this.paginate.page = page;
      this.get_tickets();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_tickets(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_tickets(true);
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/ticket/export",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Tickets_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    print_table() {
      // var divToPrint = this.$refs.ticketTable;
      // console.log(document.querySelector("table"));
      // console.log(divToPrint);
      // console.log(divToPrint.$el);
      // var newWin = window.open("");
      // newWin.document.write(document.querySelector("table").innerHTML);
      // newWin.print();
      // newWin.close();
      // window.print();
      document.querySelector("table").classList.add("print");
      window.print();
    },
  },
  mounted() {
    this.get_tickets(true);
    document.title = this.$i18n.t("ticket.Tickets");
  },
};
</script>
