<template>
  <div class="filter-container">
    <v-card>
      <div class="card-header-padding pb-0">
        <!-- <v-col lg="8" md="8">
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Search
                    </h5>
                    <p class="text-sm text-body mb-0">
                      Use filters to quickly find what you're looking for.
                    </p>
                  </v-col> -->
        <v-card-text class="pb-0">
          <v-form ref="filterForm">
            <v-row>
              <v-col lg="4" md="4" v-for="(filter, i) in filters" :key="i">
                <label class="text-xs text-typo font-weight-bolder">{{
                  filter.label
                }}</label>

                <v-select
                  outlined
                  v-if="filter.type == 'select'"
                  v-model="filter.value"
                  :name="filter.value"
                  :items="filter.items"
                  :item-text="filter.itemText"
                  :item-value="filter.itemValue"
                  :label="$t('general.choose')"
                  hide-details
                  dense
                >
                </v-select>

                <v-text-field
                  v-else
                  outlined
                  :type="filter.type"
                  v-model="filter.value"
                  :name="filter.value"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex" :class="classLang">
            <v-btn icon color="#047A6F" @click="get_data()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="resetFilters()">
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "FormFilter",
  props: ["filters"],
  data() {
    return {
      search: "",
    };
  },
  methods: {
    get_data() {
      this.$emit("get_data");
    },
    resetFilters() {
      this.$refs.filterForm.reset();
      this.$emit("reset");
    },
  },
  computed: {
    classLang() {
      return {
        "ml-0 mr-auto": this.$vuetify.rtl,
        "mr-0 ml-auto": !this.$vuetify.rtl,
      };
    },
  },
};
</script>
<style lang="css">
.v-sheet.v-card {
  border-radius: 0;
}
</style>
