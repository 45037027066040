<template>
  <div class="position-relative" id="main_table">
    <Loading v-if="loading"></Loading>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :options.sync="options"
      class="table responsive"
      :search="paginate.search"
      :page.sync="paginate.page"
      :items-per-page="paginate.itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      mobile-breakpoint="0"
    >
      <!-- :sort-by="sortBy"
      :sort-desc="SortDescing"
      multi-sort -->
      <!-- :items-per-page-options="[10, 20, 30]" -->
      <!-- :items-per-page="paginate.itemsPerPage" -->
      <!-- :custom-filter="filterOnlyCapsText" -->
      <template v-slot:top>
        <div id="btn_row">
          <v-row class="py-0 my-0">
            <v-col cols="12" sm="6">
              <v-btn
                v-if="btn_table.excel"
                :ripple="false"
                :class="classLangExcel"
                class="text-white mt-3 mb-5 font-weight-bolder"
                small
                color="#047a6f"
                @click="event_btn('excel')"
                :loading="btn_loading.excel"
              >
                <span slot="loader">
                  <v-progress-circular
                    style="width: 20px; height: 20px"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </span>
                {{ $t("excel") }}
              </v-btn>
              <v-btn
                v-if="btn_table.print"
                :ripple="false"
                :class="classLangPrint"
                class="text-white mt-3 mb-5 font-weight-bolder"
                small
                color="#c7373a"
                @click="event_btn('print')"
                :loading="btn_loading.print"
              >
                <span slot="loader">
                  <v-progress-circular
                    style="width: 20px; height: 20px"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </span>
                {{ $t("print") }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" :class="classLang">
              <div
                class="
                  mx-10
                  mt-0
                  mb-5
                  pt-3
                  text-h5 text-typo
                  font-weight-bolder
                "
              >
                {{ $t("total") }} : {{ paginate.total }}
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
      <!-- here -->

      <template v-slot:item.quote="{ item }" :id="item.id">
        <div v-html="item.quote"></div>
      </template>

      <template v-slot:item.body="{ item }" :id="item.id">
        <div v-html="item.body"></div>
      </template>

      <template v-slot:item.roles="{ item }" :id="item.id">
        <div v-if="item.roles.length > 0">
          <div v-for="(role, i) in item.roles" :key="i">
            <v-chip label outlined color="green" class="py-1 mt-1 px-2 my-0">
              <span class="text-caption ls-0">{{ role.name }}</span>
            </v-chip>
          </div>
        </div>
        <div v-else>-</div>
      </template>

      <template v-slot:item.photo="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1">
            <v-list-item-avatar rounded :size="36" class="my-0 ml-0">
              <v-img
                v-if="item.photo"
                :alt="item.photo"
                :src="item.photo"
                class="border-radius-lg"
              ></v-img>
              <v-img
                v-else
                src="@/assets/img/alborj/no-avatar.jpg"
                alt="Avatar"
                class="border-radius-lg"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="mb-0 text-sm text-typo font-weight-bold"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:item.image="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1 justify-content-center">
            <a :href="item.image" target="__blank">
              <v-img
                v-if="item.image"
                :alt="item.image"
                :src="item.image"
                style="width: 150px"
                class="border-radius-lg"
              ></v-img>
            </a>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:item.answer="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1">
            <a
              :href="$baseURL + item.answer"
              target="__blank"
              v-if="item.type == 'file'"
            >
              <v-btn
                icon
                class="shadow-0 px-0"
                small
                style="color: black"
                simple
              >
                <v-icon size="18" class="rotate-180"
                  >fas fa-file-pdf text-lg me-1</v-icon
                >
                {{ $t("ticket.file") }}
              </v-btn>
              <!-- <v-img
                v-if="item.answer"
                :alt="item.answer"
                :src="$baseURL + '' + item.answer"
                style="width: 150px"
                class="border-radius-lg"
              ></v-img> -->
            </a>
            <div v-else>
              {{ item.answer }}
            </div>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:item.applicant_ticket="{ item }" :id="item.id">
        <p>
          <span v-if="item.ticket_customer">
            {{ item.ticket_customer.users.user }}
          </span>
          <!-- <span v-if="item.ticket_customer.p_company_name != null">
            / {{ item.ticket_customer.p_company_name }}
          </span> -->
        </p>
      </template>

      <template v-slot:item.avatar="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1">
            <v-list-item-avatar rounded :size="36" class="my-0 ml-0">
              <v-img
                v-if="item.users.avatar"
                :alt="item.users.avatar"
                :src="$baseURL + 'storage/users/' + item.users.avatar"
                class="border-radius-lg"
              ></v-img>
              <v-img
                v-else
                src="@/assets/img/alborj/no-avatar.jpg"
                alt="Avatar"
                class="border-radius-lg"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="mb-0 text-sm text-typo font-weight-bold"
              >
                {{ item.users.user }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:item.chip="{ item }" :id="item.id">
        <div>
          <v-chip
            v-if="item.statuses.id == 1"
            label
            outlined
            color="orange"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{ item.statuses.status }}</span>
          </v-chip>
          <v-chip
            v-if="item.statuses.id == 2"
            label
            outlined
            color="blue"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{ item.statuses.status }}</span>
          </v-chip>
          <v-chip
            v-if="item.statuses.id == 3"
            label
            outlined
            color="green"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{ item.statuses.status }}</span>
          </v-chip>
          <v-chip
            v-if="item.statuses.id == 4 || item.statuses.id == 5"
            label
            outlined
            color="red"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{ item.statuses.status }}</span>
          </v-chip>
        </div>
      </template>

      <template v-slot:item.chips_level="{ item }" :id="item.id">
        <div>
          <v-chip
            v-if="item.importance_level == 1"
            label
            outlined
            color="orange"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{
              item.importance_level_text
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.importance_level == 2"
            label
            outlined
            color="green"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{
              item.importance_level_text
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.importance_level == 3"
            label
            outlined
            color="red"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{
              item.importance_level_text
            }}</span>
          </v-chip>
        </div>
      </template>

      <template v-slot:item.btns="{ item }" :id="item.id">
        <td class="btn_action">
          <div class="d-flex justify-content-center" :id="item.id">
            <div v-for="(btn, index) in btns" :key="index">
              <div class="d-inline-block" v-if="btn.permission">
                <span
                  v-if="
                    $store.getters['auth/str_per'].indexOf(btn.permission) > -1
                  "
                  :title="$t('general.' + btn.text.replace('_', ' '))"
                  :class="`btn font-weight-bold  mr-4`"
                  @click="action(item.id, btn.text, item)"
                >
                  <!-- <v-btn :title="$t('general.'+btn.text.replace('_',' '))" :class="`w-45 font-weight-bold py-2 px-1 mr-2 text-white ${btn.color}`" -->
                  <!-- $store.state.form.loader -->
                  <v-icon v-if="btn.type == 'icon'">{{ btn.icon }}</v-icon>
                  <div v-else>
                    {{ $t("general." + btn.text.replace("_", " ")) }}
                  </div>
                </span>
              </div>
              <div class="d-inline-block" v-else>
                <span
                  :title="$t('general.' + btn.text.replace('_', ' '))"
                  :class="`btn font-weight-bold  mr-4`"
                  @click="action(item.id, btn.text, item)"
                >
                  <v-icon v-if="btn.type == 'icon'">{{ btn.icon }}</v-icon>
                  <div v-else>
                    {{ $t("general." + btn.text.replace("_", " ")) }}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-row class="pt-3" id="pagination_table">
      <v-col md="3" v-if="paginate.row_pre_page">
        <div class="d-flex pl-3">
          <label for="" style="font-size: 14px" class="mr-3 mt-2"
            >Rows per Page :
          </label>
          <v-select
            style="width: 36%"
            v-model="paginate.itemsPerPage"
            hide-details
            @change="changeItemPage"
            :items="[10, 20, 30, 50, 100]"
            dense
            outlined
          ></v-select>
        </div>
      </v-col>

      <v-col :md="paginate.row_pre_page ? '9' : '12'" class="text-right">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#06ab95"
          v-model="paginate.page"
          :length="pages"
          @input="handlePageChange"
          total-visible="5"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Loading from "./Loading.vue";
export default {
  name: "Table",
  props: [
    "headers",
    "items",
    "btns",
    "loading",
    "data",
    "paginate",
    "btn_table",
    "btn_loading",
  ],
  data() {
    return {
      search: "",
      options: {},
      // disabled: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    pages() {
      return Math.ceil(this.paginate.total / this.paginate.itemsPerPage) | 0;
    },
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
    classLangPrint() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
    classLangExcel() {
      return {
        "mr-10": this.$vuetify.rtl,
        "ml-10": !this.$vuetify.rtl,
      };
    },
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.$emit("set_option", this.options);
      },
      deep: true,
    },
  },
  methods: {
    resetOption() {
      this.options = {
        sortDesc: [],
        sortBy: [],
      };
    },
    handlePageChange(page) {
      this.$emit("get_data_paginate", page);
    },
    changeItemPage($eve) {
      // alert($eve);
      this.$emit("change_pre_page", parseInt($eve));
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    action: function (id, name, item) {
      this.$emit("action", id, name, item);
    },
    event_btn: function (name) {
      if (name == "print") {
        var table = document.querySelector("table");
        setTimeout(() => {
          var newWin = window.open("");
          var data = `<html><head><title>table</title>
          <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">
          <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/buttons/2.2.3/css/buttons.dataTables.min.css">
          <link rel="stylesheet" type="text/css" href="${this.$baseURL}css/table.css">
          </head>
          <body class="dt-print-view">
            <table class="dataTable">
              ${table.innerHTML}
              </table>
            </body>
          </html>`;
          newWin.document.write(data);
          setTimeout(() => {
            newWin.print();
            newWin.close();
          }, 5000);
        }, 200);
      } else {
        this.$emit("event_btn", name);
      }
    },
  },
  created() {
    if (this.paginate.row_pre_page == undefined) {
      this.paginate.row_pre_page = true;
    }
  },
};
</script>
<style>
.v-data-table {
  border-radius: 0 !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
.theme--light.v-pagination .v-pagination__item--active {
  background-image: linear-gradient(310deg, #047a6f, #2fbcae) !important;
}
</style>